<template>
  <g :fill="color" fill-rule="evenodd">
    <path
      fill="none"
      fill-rule="evenodd"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M1 13l6-6-6-6"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
